
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@import '../../style/globalColorVars.scss';
@import '../../style/font-family.scss';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';


@include media-breakpoint-down(md) {
	.topics-container {
		margin-bottom: 30px;
	}
}

.topics-container {
 background-color: $background-default;
 box-shadow: 0 0 3px $box-shadow-color;
 border-radius: 6px;
 padding: 15px;

 h2 {
  font-size: 16px;
 }

 .topics-tables {
  margin-bottom: 0;

  thead {
   display:none
  }

  tbody tr {
   font-size: 14px;
   color: $primary-text;
   border-color: $table-tr-divider;
   position: relative;

   &:last-child {
    border: $bt-white;
   }

   td.current-position {
    width: 30px;
   }

   .topic-link {
    color: $primary-text;
    @include roboto-regular;
    display: block;
    height: 100%;
   }
  }
 }
}



/* Most Popular */


.trending-movement {
 width: 80px;

 a {
  text-align: right;
 }

 svg {
  width: 11px;
 }

 span {
  float: right;
  margin-left: 11px;
 }

 .fa-caret-up {
  color: $icon-green;
 }

 .fa-caret-down {
  color: $icon-red;
 }

 .fa-caret-right {
  color: $icon-light-blue;
  margin-left: 3px;
 }
}

